import React from "react";

export default function Server() {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Server">
        <g id="Group">
          <g id="Group_2">
            <g id="Group_3">
              <path
                id="Vector"
                d="M266.667 298.667H437.334C443.222 298.667 448.001 293.888 448.001 288C448.001 282.112 443.222 277.333 437.334 277.333H266.667C260.779 277.333 256 282.112 256 288C256 293.888 260.779 298.667 266.667 298.667Z"
                fill="black"
              />
              <path
                id="Vector_2"
                d="M512 202.667V160C512 149.696 508.928 140.16 503.851 131.989C503.723 131.733 503.702 131.434 503.552 131.2L437.589 25.173C427.797 9.429 410.859 0 392.299 0H119.723C101.142 0 84.224 9.429 74.432 25.173L8.469 131.2C8.341 131.413 8.341 131.648 8.213 131.883C3.093 140.053 0 149.653 0 160V202.667C0 220.16 8.597 235.606 21.675 245.334C8.597 255.061 0 270.485 0 288V330.667C0 348.16 8.597 363.606 21.675 373.334C8.597 383.061 0 398.485 0 416V458.667C0 488.064 23.936 512 53.333 512H193.194C199.103 512 203.861 507.221 203.861 501.333C203.861 495.445 199.082 490.666 193.194 490.666H53.333C35.69 490.666 21.333 476.309 21.333 458.666V416C21.333 398.336 35.69 384 53.333 384H231.765C237.653 384 242.432 379.221 242.432 373.333C242.432 367.445 237.653 362.666 231.765 362.666H53.333C35.69 362.666 21.333 348.309 21.333 330.666V288C21.333 270.336 35.69 256 53.333 256H458.666C476.309 256 490.666 270.357 490.666 288V330.667C490.666 335.36 489.514 340.075 487.295 344.619C484.714 349.91 486.89 356.288 492.18 358.891C497.492 361.494 503.871 359.318 506.452 354.006C510.143 346.497 511.999 338.667 511.999 330.667V288C511.999 270.507 503.402 255.061 490.324 245.333C503.403 235.584 512 220.16 512 202.667ZM92.544 36.437C98.411 26.986 108.565 21.333 119.701 21.333H392.277C403.413 21.333 413.568 26.986 419.456 36.437L463.467 107.157C461.867 106.986 460.31 106.666 458.667 106.666H53.333C51.69 106.666 50.133 106.986 48.533 107.135L92.544 36.437ZM490.667 202.667C490.667 220.31 476.31 234.667 458.667 234.667H53.333C35.69 234.667 21.333 220.31 21.333 202.667V160C21.333 142.336 35.69 128 53.333 128H458.666C476.309 128 490.666 142.336 490.666 160V202.667H490.667Z"
                fill="black"
              />
              <path
                id="Vector_3"
                d="M42.667 437.333C42.667 454.976 57.024 469.333 74.667 469.333C92.31 469.333 106.667 454.976 106.667 437.333C106.667 419.69 92.31 405.333 74.667 405.333C57.024 405.333 42.667 419.669 42.667 437.333ZM85.333 437.333C85.333 443.221 80.554 448 74.666 448C68.778 448 64 443.221 64 437.333C64 431.445 68.779 426.666 74.667 426.666C80.555 426.666 85.333 431.445 85.333 437.333Z"
                fill="black"
              />
              <path
                id="Vector_4"
                d="M437.333 362.667C435.392 362.667 433.45 362.774 431.552 362.944C413.781 336.277 383.936 320 352 320C302.336 320 261.355 357.909 256.491 406.315C231.936 411.051 213.334 432.726 213.334 458.667C213.334 488.064 237.27 512 266.667 512H437.334C478.507 512 512 478.507 512 437.333C512 396.159 478.507 362.667 437.333 362.667ZM437.333 490.667H266.667C249.024 490.667 234.667 476.31 234.667 458.667C234.667 441.024 249.024 426.667 266.667 426.667C272.555 426.667 277.334 421.888 277.334 416C277.334 374.827 310.827 341.333 352.001 341.333C378.668 341.333 403.521 356.032 416.812 379.69C419.031 383.637 423.425 385.727 427.991 384.959C431.042 384.426 434.135 383.999 437.335 383.999C466.732 383.999 490.668 407.935 490.668 437.332C490.668 466.729 466.731 490.667 437.333 490.667Z"
                fill="black"
              />
              <path
                id="Vector_5"
                d="M437.333 149.333H266.667C260.779 149.333 256 154.112 256 160C256 165.888 260.779 170.667 266.667 170.667H437.334C443.222 170.667 448.001 165.888 448.001 160C448.001 154.112 443.221 149.333 437.333 149.333Z"
                fill="black"
              />
              <path
                id="Vector_6"
                d="M437.333 192H266.667C260.779 192 256 196.779 256 202.667C256 208.555 260.779 213.334 266.667 213.334H437.334C443.222 213.334 448.001 208.555 448.001 202.667C448.001 196.779 443.221 192 437.333 192Z"
                fill="black"
              />
              <path
                id="Vector_7"
                d="M74.667 149.333C57.024 149.333 42.667 163.669 42.667 181.333C42.667 198.997 57.024 213.333 74.667 213.333C92.31 213.333 106.667 198.976 106.667 181.333C106.667 163.691 92.309 149.333 74.667 149.333ZM74.667 192C68.779 192 64 187.221 64 181.333C64 175.445 68.779 170.666 74.667 170.666C80.555 170.666 85.334 175.445 85.334 181.333C85.334 187.221 80.555 192 74.667 192Z"
                fill="black"
              />
              <path
                id="Vector_8"
                d="M42.667 309.333C42.667 326.976 57.024 341.333 74.667 341.333C92.31 341.333 106.667 326.976 106.667 309.333C106.667 291.69 92.31 277.333 74.667 277.333C57.024 277.333 42.667 291.669 42.667 309.333ZM85.333 309.333C85.333 315.221 80.554 320 74.666 320C68.778 320 64 315.221 64 309.333C64 303.445 68.779 298.666 74.667 298.666C80.555 298.666 85.333 303.445 85.333 309.333Z"
                fill="black"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
