import React, { useContext } from "react";
import { QuestionSection, Radio } from "../../components/utility/quote";
import { navigate } from "gatsby-link";

import HardwareContext from "../../contexts/hardwareContext";

import Other from "../../svgs/hardware/Other";
import Router from "../../svgs/hardware/RouterHardware";
import Server from "../../svgs/hardware/Server";
import Switch from "../../svgs/hardware/Switch";

const Next = "/hardware/Questions/2";
const Points = [
  {
    question: "What type of service do you require?",
    progress: "20%",
    options: [
      { answer: "Switch upgrades", to: Next, icon: <Switch /> },
      { answer: "Router upgrades", to: Next, icon: <Router /> },
      { answer: "Server upgrade/Install", to: Next, icon: <Server /> },
      { answer: "Other", to: Next, icon: <Other /> },
    ],
  },
];

export default function Question1({ title }) {
  const { que1, setQue1 } = useContext(HardwareContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <Radio
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que1 === items.answer}
              onChange={(e) => {
                setQue1(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
