import React from "react";

export default function Switch() {
  return (
    <svg
      width="600"
      height="600"
      viewBox="0 0 600 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Switch">
        <g id="surface1">
          <path
            id="Vector"
            d="M570 160H30C13.4336 160 0 173.434 0 190V370C0 386.566 13.4336 400 30 400H62.1914L70.2969 432.422C71.4102 436.875 75.4102 440 80 440H120C124.59 440 128.59 436.875 129.703 432.422L137.809 400H462.191L470.297 432.422C471.41 436.875 475.41 440 480 440H520C524.59 440 528.59 436.875 529.703 432.422L537.809 400H570C586.566 400 600 386.566 600 370V190C600 173.434 586.566 160 570 160ZM112.191 420H87.8086L82.8086 400H117.191L112.191 420ZM512.191 420H487.809L482.809 400H517.191L512.191 420ZM580 370C580 375.523 575.523 380 570 380H30C24.4766 380 20 375.523 20 370V190C20 184.477 24.4766 180 30 180H570C575.523 180 580 184.477 580 190V370Z"
            fill="black"
          />
          <path id="Vector_2" d="M520 200H560V220H520V200Z" fill="black" />
          <path
            id="Vector_3"
            d="M540 230C523.434 230 510 243.434 510 260C510 276.566 523.434 290 540 290C556.566 290 570 276.566 570 260C570 243.434 556.566 230 540 230ZM540 270C534.477 270 530 265.523 530 260C530 254.477 534.477 250 540 250C545.523 250 550 254.477 550 260C550 265.523 545.523 270 540 270Z"
            fill="black"
          />
          <path
            id="Vector_4"
            d="M110 260H50C44.4766 260 40 264.477 40 270V330C40 335.523 44.4766 340 50 340H110C115.523 340 120 335.523 120 330V270C120 264.477 115.523 260 110 260ZM100 320H60V280H100V320Z"
            fill="black"
          />
          <path
            id="Vector_5"
            d="M200 260H140C134.477 260 130 264.477 130 270V330C130 335.523 134.477 340 140 340H200C205.523 340 210 335.523 210 330V270C210 264.477 205.523 260 200 260ZM190 320H150V280H190V320Z"
            fill="black"
          />
          <path
            id="Vector_6"
            d="M290 260H230C224.477 260 220 264.477 220 270V330C220 335.523 224.477 340 230 340H290C295.523 340 300 335.523 300 330V270C300 264.477 295.523 260 290 260ZM280 320H240V280H280V320Z"
            fill="black"
          />
          <path
            id="Vector_7"
            d="M380 260H320C314.477 260 310 264.477 310 270V330C310 335.523 314.477 340 320 340H380C385.523 340 390 335.523 390 330V270C390 264.477 385.523 260 380 260ZM370 320H330V280H370V320Z"
            fill="black"
          />
          <path
            id="Vector_8"
            d="M470 260H410C404.477 260 400 264.477 400 270V330C400 335.523 404.477 340 410 340H470C475.523 340 480 335.523 480 330V270C480 264.477 475.523 260 470 260ZM460 320H420V280H460V320Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
}
