import React from "react";

export default function RouterHardware() {
  return (
    <svg
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Router" clip-path="url(#clip0)">
        <g id="Group">
          <path
            id="Vector"
            d="M467 285.5H428.748L509.787 96.409C513.051 88.795 509.523 79.976 501.909 76.713C494.295 73.45 485.476 76.977 482.213 84.591L396.109 285.5H115.891L29.787 84.591C26.524 76.977 17.707 73.448 10.091 76.713C2.477 79.976 -1.05 88.794 2.213 96.409L83.252 285.5H45C20.187 285.5 0 305.687 0 330.5V420.5C0 445.313 20.187 465.5 45 465.5H61V481.5C61 489.784 67.716 496.5 76 496.5C84.284 496.5 91 489.784 91 481.5V465.5H421V481.5C421 489.784 427.716 496.5 436 496.5C444.284 496.5 451 489.784 451 481.5V465.5H467C491.813 465.5 512 445.313 512 420.5V330.5C512 305.687 491.813 285.5 467 285.5ZM482 420.5C482 428.771 475.271 435.5 467 435.5H45C36.729 435.5 30 428.771 30 420.5V330.5C30 322.229 36.729 315.5 45 315.5H467C475.271 315.5 482 322.229 482 330.5V420.5Z"
            fill="black"
          />
          <path
            id="Vector_2"
            d="M224.241 208.697C218.383 214.555 218.383 224.052 224.241 229.91C230.098 235.768 239.596 235.768 245.454 229.91C247.101 228.263 250.662 225.5 256 225.5C261.338 225.5 264.898 228.263 266.546 229.91C272.403 235.768 281.901 235.769 287.759 229.91C293.617 224.052 293.617 214.555 287.759 208.697C282.83 203.769 272.15 195.5 256 195.5C239.85 195.5 229.17 203.769 224.241 208.697V208.697Z"
            fill="black"
          />
          <path
            id="Vector_3"
            d="M118.104 102.685C154.954 65.809 203.926 45.5 256 45.5C308.074 45.5 357.046 65.809 393.896 102.685C399.748 108.543 409.246 108.551 415.109 102.693C420.969 96.838 420.972 87.34 415.117 81.48C372.601 38.932 316.092 15.5 256 15.5C195.908 15.5 139.4 38.932 96.884 81.479C91.028 87.339 91.031 96.836 96.892 102.692C102.751 108.548 112.25 108.544 118.104 102.685V102.685Z"
            fill="black"
          />
          <path
            id="Vector_4"
            d="M372.656 145.101C378.516 139.247 378.521 129.749 372.667 123.888C341.495 92.685 300.063 75.5 256 75.5C211.937 75.5 170.504 92.685 139.333 123.888C133.479 129.749 133.483 139.246 139.344 145.101C145.205 150.956 154.703 150.951 160.557 145.09C186.06 119.56 219.956 105.5 255.999 105.5C292.042 105.5 325.937 119.56 351.441 145.09C357.294 150.948 366.79 150.96 372.656 145.101V145.101Z"
            fill="black"
          />
          <path
            id="Vector_5"
            d="M330.218 187.496C336.076 181.638 336.076 172.141 330.218 166.283C310.367 146.432 284.009 135.5 256 135.5C227.991 135.5 201.633 146.432 181.783 166.283C175.925 172.141 175.925 181.638 181.783 187.496C187.64 193.354 197.138 193.355 202.996 187.496C217.18 173.312 236.004 165.5 256 165.5C275.996 165.5 294.82 173.312 309.005 187.496C314.862 193.354 324.36 193.354 330.218 187.496Z"
            fill="black"
          />
          <path
            id="Vector_6"
            d="M316 345.5C307.716 345.5 301 352.216 301 360.5V390.5C301 398.784 307.716 405.5 316 405.5C324.284 405.5 331 398.784 331 390.5V360.5C331 352.216 324.284 345.5 316 345.5Z"
            fill="black"
          />
          <path
            id="Vector_7"
            d="M376 345.5C367.716 345.5 361 352.216 361 360.5V390.5C361 398.784 367.716 405.5 376 405.5C384.284 405.5 391 398.784 391 390.5V360.5C391 352.216 384.284 345.5 376 345.5Z"
            fill="black"
          />
          <path
            id="Vector_8"
            d="M436 345.5C427.716 345.5 421 352.216 421 360.5V390.5C421 398.784 427.716 405.5 436 405.5C444.284 405.5 451 398.784 451 390.5V360.5C451 352.216 444.284 345.5 436 345.5Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
